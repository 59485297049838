import * as React from 'react';
import { Socket } from 'socket.io-client';

export enum SocketEventType {
  CaseReportUpdated = 'CaseReport.Updated',
  MediaItemUpdated = 'MediaItem.Updated',
}

export interface SocketServerToClientEvents {
  [SocketEventType.CaseReportUpdated]: (
    caseReportId: string,
    updatedAt: string,
    authorId?: string
  ) => void;
  [SocketEventType.MediaItemUpdated]: (mediaItemId: string, updatedAt: string) => void;
}

type CustomSocket = Socket<SocketServerToClientEvents>;

const SocketContext = React.createContext<CustomSocket | null>(null);

export default SocketContext;
