import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import useMeQuery from '../../model/hooks/use-me-query';
import getLocalizedPath from '../../utils/get-localized-path/get-localized-path';

interface AppContainerProps {}

const AppContainer: React.FC<AppContainerProps> = ({ children }) => {
  const router = useRouter();
  const { locale, asPath, defaultLocale } = router;
  const { data: user } = useMeQuery();

  useEffect(() => {
    if (user && user.preferences.language !== locale) {
      const newPath = getLocalizedPath({
        path: asPath,
        defaultLocale,
        locale: user.preferences.language,
      });
      window.location.replace(newPath);
    }
  }, [user, locale, asPath, defaultLocale]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default AppContainer;
